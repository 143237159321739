
import '@/css/home-login.css';
import {Component} from 'vue-property-decorator';
import {ILogin} from '@/services/dto/ILogin';
import {services, store} from '@/main';
import AbstractComponentVue from '../services/AbstractComponentVue';
import AlertService from '@/utils/AlertService';

/**
 * Attention, on étend de AbstractComponentVue qui lui même étand de Vue.
 *
 */
@Component({})
export default class Login extends AbstractComponentVue {
  private username: string = '';
  private password: string = '';
  private progressValue: number = 20;
  private progressAnimate: boolean = false;
  private progessShow: boolean = false;
  private authenticationError: boolean = false;

  private login(): void {
    this.progessShow = true;
    this.progressAnimate = true;
    this.progressValue = 100;
    this.authenticationError = false;
    const nomUtilisateur: string = this.username.trim().toLowerCase();
    const data: ILogin = {login: nomUtilisateur, password: this.password};
    services.loginService.connect(data)
        .then(this.loginPostProcessing)
        .catch(this.loginError);
  }

  private get isButtonDisabled(): boolean {
    return this.password.length === 0 || this.username.length === 0;
  }

  /**
   * Traitement après le login
   * @param retour
   */
  private loginPostProcessing(retour: any): void {
    if (retour.status === 200) {
      if (retour.data && retour.data.accountNotActivated) {

        AlertService.customAlert(
            'Confirmation',
            'Je confirme être le destinataire du code d\'activation.',
            'info',
            'Confirmer',
            true,
            this.activationAccount,
            true,
        );
      } else {
        // Charge les informations sur l'utilisateur courant.
        services.restService.callGet('/api/v1/connected-user/current')
            .then(services.loginService.loadUserPostProcessing);
      }
    }
    this.progessShow = false;
    this.progressValue = 20;
  }

  private loginError(response: any) {
    if (response.status === 423) {
      // On ouvre le Popup indiquant un compte bloqué temporairement.
      AlertService.warning('Suite à de nombreuses tentatives de connexion, votre compte a été temporairement bloqué.' +
          ' Veuillez réessayer de vous connecter dans quelques minutes.');
      this.authenticationError = true;
    } else { // Gestion des erreurs (401 notamment)
      this.authenticationError = true;
    }
    this.progessShow = false;
    this.progressValue = 20;
  }

  private created() {
    // On détecte si un utilisateur est connecté, auquel cas on redirige directement vers home
    if (store.getters.isConnectedUser) {
      services.routageService.goTo('/home');
    }
  }

  private activationAccount() {
    // On redirige vers la page de d'activation d'account
    services.routageService
    	.goTo('/first-connexion/' + this.username.trim().toLowerCase() + '/activation/' + this.password);
  }
}
